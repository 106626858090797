/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import api from '../../services/api';
import handleError from '../../utils/handleError';
import {
  ContainerModal,
  ContainerTitle,
  Title,
  Section,
  ContainerRow,
  Label,
  ComponentList,
  List,
  TextId,
  TextType,
  IconAlert,
  IconPin,
  ButtonClose,
} from './_modalAlertHistory';
import Alert from '../../assets/img/alert.svg';
import Pin from '../../assets/icons/marker.svg';
import IconClose from '../../assets/icons/close.svg';

interface Props {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  option: string;
}

export interface Users {
  id: string;
  name: string;
  email: string;
  cpf: string;
  password: string;
  avatar: null;
  role: string;
  created_at: string;
  updated_at: string;
}

export interface ResponseWarning {
  results: Result[];
  total: number;
}

export interface Result {
  id: string;
  latitude: string;
  longitude: string;
  title: string;
  describe: string;
  visible: boolean;
  user_id: string;
  user: User;
  created_at: string;
  updated_at: string;
}

export interface User {
  id: string;
  name: string;
  email: string;
  avatar: null;
  role: string;
  phone: null;
  user_valid: boolean;
  boat_id: string;
  boat: Boat;
  favorite_establishments: FavoriteEstablishment[];
  created_at: string;
  updated_at: string;
  avatar_url: string;
}

export interface Boat {
  id: string;
  name: string;
  model: string;
  construction_year: string;
  have_sailor: boolean;
  location: string;
  state: string;
  city: string;
  user_id: string;
  created_at: string;
  updated_at: string;
}

export interface FavoriteEstablishment {
  id: string;
  name: string;
  logotype: string;
  category: string;
  latitude: string;
  longitude: string;
  address: string;
  describe: string;
  telephone: string;
  youtube: string;
  facebook: string;
  instagram: string;
  site: string;
  images: Image[];
  business_days: BusinessDay[];
  services: Service[];
  visible: boolean;
  created_at: string;
  updated_at: string;
  logotype_url: string;
  is_open: boolean;
}

export interface BusinessDay {
  id: string;
  week_day: string;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
}

export interface Image {
  id: string;
  name: string;
  establishment_id: string;
  created_at: string;
  img_url: string;
}

export interface Service {
  id: string;
  name: string;
  icon: string;
  describe: string;
  created_at: string;
  icon_url: string;
}

export const ModalAlertHistory = ({ setOpenModal, option }: Props) => {
  const [warningAccept, setWarning] = useState<Result[]>();
  const [warning, setWarningAccept] = useState<Result[]>();

  const fetchApiInsurance = async () => {
    try {
      const { data } = await api.get<ResponseWarning>('/warning', {
        params: {
          visible: 'none',
          created: 'ASC',
        },
      });

      setWarning(data.results.filter(item => item.visible === true));
      setWarningAccept(data.results.filter(item => item.visible === false));
    } catch (error) {
      handleError(error, 'error');
    }
  };

  useEffect(() => {
    fetchApiInsurance();
  }, []);

  const renderListWarningAccept = () => {
    if (warningAccept) {
      return warningAccept.map((item, index) => (
        <List>
          <TextId>#{index + 1}</TextId>
          <TextType>
            <IconAlert src={Alert} alt="Icone de Alerta" />
            ALERTA | Cuidado! {item.title.slice(0, 18)}
            <IconPin src={Pin} alt="Icone de Alerta" />
            <div style={{ fontSize: '10pt' }}>
              {item.latitude.slice(0, 10)}, {item.longitude.slice(0, 10)}
            </div>
          </TextType>
        </List>
      ));
    }
  };

  const renderListWarningNoAccept = () => {
    if (warning) {
      return warning.map((item, index) => (
        <List>
          <TextId>#{index + 1}</TextId>
          <TextType>
            <IconAlert src={Alert} alt="Icone de Alerta" />
            ALERTA | Cuidado! {item.title.slice(0, 18)}
            <IconPin src={Pin} alt="Icone de Alerta" />
            <div style={{ fontSize: '10pt' }}>
              {item.latitude.slice(0, 10)}, {item.longitude.slice(0, 10)}
            </div>
          </TextType>
        </List>
      ));
    }
  };

  return (
    <ContainerModal>
      <Section>
        <ContainerTitle>
          <Title>Solicitações</Title>
          <ButtonClose
            src={IconClose}
            alt="Icone de Fechar"
            onClick={() => setOpenModal(false)}
          />
        </ContainerTitle>
        <div className="overflow">
          <ContainerRow>
            <Label>{option === 'aceitas' ? 'Aceitas' : 'Rejeitadas'}</Label>
          </ContainerRow>
          <ComponentList>
            {option === 'aceitas'
              ? renderListWarningAccept()
              : renderListWarningNoAccept()}
          </ComponentList>
        </div>
      </Section>
    </ContainerModal>
  );
};
