/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import {
  ButtonOption,
  Container,
  Limit,
  Pointer,
  Row,
  Section,
  SectionAlert,
} from './_modalAlerts';

import IconPin from '../../assets/icons/blackPin.svg';
import IconAlert from '../../assets/icons/blackAlert.svg';
import IconSos from '../../assets/icons/blackSos.svg';
import Ativo from '../../assets/Ativo.svg';
import api from '../../services/api';
import handleError from '../../utils/handleError';
import pixel from '../../utils/size';
import { ResponseSOS, Result, User } from '../ModalSos/ModalSos';
import logotipo from '../../assets/img/logotipo.svg';
import { ResponseConcierge } from '../../pages/MapsPage/interface';

interface Props {
  notification2: boolean;
  setNotification2: Dispatch<SetStateAction<boolean>>;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setModalSos: Dispatch<SetStateAction<boolean>>;
  setNotification: Dispatch<SetStateAction<boolean>>;
  notification: boolean;
  concierge: ResponseConcierge[];
  setIdChat: Dispatch<SetStateAction<string>>;
  setName: Dispatch<SetStateAction<string>>;
  setType: Dispatch<SetStateAction<string>>;
}

export interface Warning {
  results: Alert[];
}

export interface Alert {
  id: string;
  latitude: string;
  longitude: string;
  describe: string;
  visible: boolean;
  created_at: string;
  user: User;
  type: string;
}

export const ModalAlerts = ({
  setOpenModal,
  setModalSos,
  setNotification,
  notification,
  setNotification2,
  notification2,
  concierge,
  setIdChat,
  setName,
  setType,
}: Props) => {
  const [notify, setNotify] = useState<Result[]>();
  const [notifyAlert, setNotifyAlert] = useState<Alert[]>();
  const [numberLimit, setNumberLimit] = useState<number>(20);

  const history = useHistory();

  const fetchApiSos = async () => {
    try {
      const { data } = await api.get<ResponseSOS>('/sos/', {
        params: {
          page: 1,
          limit: 5,
        },
      });

      data.results.map(item => {
        const date = new Date(item.sos.created_at);
        item.sos.created_at = date.toLocaleDateString();
      });

      data.results.map(item => (item.sos.type = 'SOS'));

      setNotify(data.results.filter(item => item.sos.status !== 'finished'));
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const fetchApiAlerts = async () => {
    try {
      const { data } = await api.get<Warning>('/warning', {
        params: {
          visible: 'none',
        },
      });

      data.results.map(item => {
        const date = new Date(item.created_at);
        item.created_at = date.toLocaleDateString();
      });

      data.results.map(item => (item.type = 'ALERTA'));

      setNotifyAlert(data.results.filter(item => item.visible === null));
    } catch (error) {
      handleError(error, 'error');
    }
  };

  useEffect(() => {
    fetchApiSos();
    fetchApiAlerts();
  }, []);

  const updateWarning = async (value: string) => {
    try {
      await api.put(`/warning/${value}?visible=true`, {
        visible: true,
      });

      handleError('Alerta foi aceito!', 'success');
      fetchApiAlerts();
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const deleteWarning = async (value: string) => {
    try {
      await api.put(`/warning/${value}`, {
        visible: false,
      });

      handleError('Alerta foi recusado!', 'success');
      fetchApiAlerts();
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const renderConciergeList = () => {
    return (
      concierge &&
      concierge.map(item => (
        <SectionAlert>
          <Section
            onClick={() => {
              setType('concierge');
              setIdChat(item.id);
              setName(item.user.name);
              setOpenModal(false);
            }}
          >
            <Row
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={Ativo}
                  alt="Icone do alerta"
                  style={{ marginRight: '5px', height: '30px' }}
                />
                <p>Concierge</p>
              </div>

              {!item.admin_viewed && <Pointer />}
            </Row>
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: pixel(16),
              }}
            >
              {item.user.name}
            </Row>
            <Row
              style={{
                fontSize: pixel(12),
                marginBottom: pixel(5),
              }}
            >
              {format(parseISO(item.created_at), 'dd/MM/yyyy')}
            </Row>
          </Section>
          <hr />
        </SectionAlert>
      ))
    );
  };

  const renderNotify = () => {
    return (
      notify &&
      notify.slice(0, numberLimit).map(item => (
        <SectionAlert
          onClick={() => {
            setOpenModal(false);
            setModalSos(true);
            history.push(`/maps/${item.sos.id}`);
            setNotification(false);
          }}
        >
          <Section>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex' }}>
                <img
                  src={IconSos}
                  alt="Icone do alerta"
                  style={{ marginRight: '5px' }}
                />
                <p>{item.sos.type.toUpperCase()}</p>
              </div>

              {item.sos.is_read === false ? <Pointer /> : null}
            </Row>
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: pixel(16),
              }}
            >
              {item.sos.describe.length > 17
                ? `${item.sos.describe.slice(0, 17)}...`
                : item.sos.describe}

              <img
                src={
                  item.sos.insurance
                    ? item.sos.insurance.logotype_url
                    : logotipo
                }
                alt=""
                width={80}
                height={80}
              />
            </Row>
            <Row
              style={{
                fontSize: pixel(12),
                marginTop: pixel(-12),
                marginBottom: pixel(5),
              }}
            >{`${item.sos.created_at}`}</Row>
            <Row>
              <img src={IconPin} alt="Icone de localização" />
              <h6>
                {item.sos.latitude.slice(0, 10)}/
                {item.sos.longitude.slice(0, 10)}
              </h6>
            </Row>
          </Section>
          <hr />
        </SectionAlert>
      ))
    );
  };

  const renderNotifyAlert = () => {
    if (notifyAlert) {
      return notifyAlert.map(item => (
        <>
          <Section>
            <Row>
              <img src={IconAlert} alt="Icone do alerta" />
              <p>{item.type.toUpperCase()}</p>
            </Row>
            <Row>
              {item.describe.length > 21
                ? `${item.describe.slice(0, 21)}...`
                : item.describe}
            </Row>
            <Row style={{ fontSize: pixel(12) }}>{`${item.created_at}`}</Row>
            <Row>
              <img src={IconPin} alt="Icone de localização" />
              <h6>
                {item.latitude.slice(0, 10)}/{item.longitude.slice(0, 10)}
              </h6>
            </Row>
            <Row>
              <ButtonOption
                option
                onClick={() => {
                  deleteWarning(item.id);
                  setNotification2(false);
                }}
              >
                Recusar
              </ButtonOption>
              <ButtonOption
                onClick={() => {
                  updateWarning(item.id);
                  setNotification2(false);
                }}
              >
                Aceitar
              </ButtonOption>
            </Row>

            {notification2 ? <Pointer /> : null}
          </Section>
          <hr />
        </>
      ));
    }

    return <div />;
  };

  const addLimit = () => {
    setNumberLimit(props => props + 10);
  };

  const renderLimit = () => {
    if (notify) {
      if (notify?.length > 20)
        return <Limit onClick={() => addLimit()}>ver mais</Limit>;
    }

    return null;
  };

  return (
    <Container>
      {renderConciergeList()}
      {renderNotifyAlert()}
      {renderNotify()}
      {renderLimit()}
    </Container>
  );
};
